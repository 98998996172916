import React, {useEffect} from 'react';
import {RouterProvider} from "react-router-dom";
import {Provider} from 'react-redux';

import 'styles/App.scss';
import router from "./router";
import store from "./store";
import {LayoutWrapper} from "./layout";

const App = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=onload';
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    },[])

    return (
        <React.StrictMode>
            <Provider store={store}>
                <LayoutWrapper>
                    <RouterProvider router={router}/>
                </LayoutWrapper>
            </Provider>
        </React.StrictMode>
    );
}

export default App;
