import React, {FC, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {isReady} from "../store/recaptchaSlice";

interface ReCaptchaProps {
    id: string;
    type?: string;
}

const ReCaptcha: FC<ReCaptchaProps> = ({id, type}) => {
    const dispatch = useDispatch<any>();
    const [show, setShow] = useState<boolean>(false);
    window.showRecaptcha = () => setShow(true);
    window.resetRecaptcha = () => {
        try {
            window.grecaptcha.reset();
        } catch (e) {
            console.log("Recaptcha reset");
        }
    };

    window.executeRecaptcha = () => {
        try {
            window.grecaptcha.execute().then(() => {});
        } catch (e) {
            console.log('Recaptcha execute');
        }
    }

    useEffect(() => {
        if (type !== undefined) {
            try {
                window.grecaptcha?.render(id, {
                    'sitekey': recaptchaKey(),
                    'size': 'invisible',
                    callback: (e: string) => {
                        dispatch(isReady());
                    }
                });
            } catch (e) {
                console.error(e);
            } finally {
                window.executeRecaptcha();
            }
        }
    }, []);

    useEffect(() => {
        window.onSubmit = (token:string) => {};

        return () => {
            delete window.onSubmit;
        };
    }, []);

    const recaptchaKey = (): string => {
        let key: string = '';

        if (window.captcha_public_key) {
            key = window.atob(window.captcha_public_key);
        }

        if (!key) {
            return process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
        }

        return key;
    };

    return (
        <div id={id ? id : 'recaptcha'} className="g-recaptcha" data-sitekey={recaptchaKey()} data-size={type ?? ''} data-callback="onSubmit"></div>
    );
};

export default ReCaptcha;
