import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "store";
import {
    getCityIdsMap,
    getSelectedCitiesIds,
    getSelectedVoivodeships,
    getSelectedVoivodeshipsIds,
    removeCity, removeVoivodeshipAndCities
} from "store/locationSlice";
import {fetchBox, resetPage} from "store/boxSlice";
import {getHash} from "store/formSlice";

const RenderSelectedLocations = () => {
    const dispatch = useDispatch<AppDispatch>();
    const hash = useSelector(getHash);
    const selectedVoivodeships = useSelector(getSelectedVoivodeships);
    const selectedCitiesIds = useSelector(getSelectedCitiesIds);
    const selectedVoivodeshipIds = useSelector(getSelectedVoivodeshipsIds);
    const citiesMap = useSelector(getCityIdsMap);

    const [update, setUpdate] = useState(false);

    const handleClickRemoveVoivodeship = (id: number) => {
        dispatch(removeVoivodeshipAndCities(id));
        setUpdate(true);
    }

    const handleClickRemoveCity = (id: number) => {
        dispatch(removeCity(id));
        setUpdate(true);
    }

    useEffect(() => {
        if(update){
            setUpdate(false);
            dispatch(resetPage());

            let city:any[] = [];

            selectedCitiesIds.forEach(id => {
                city = [...city, ...citiesMap[id]];
            });

            dispatch(fetchBox({hash, city: selectedCitiesIds, voivodeship: selectedVoivodeshipIds}));
        }
    }, [update, hash, selectedCitiesIds, selectedVoivodeshipIds]);

    return (
        <div className={'app_selected-locations--wrapper'}>
            {selectedVoivodeships.map(({voivodeship, cities, id}) => (
                <div className={'app_selected-locations'} key={voivodeship + id}>
                    <button
                        className={`button--small button--purple location__button--selected button--margin-top`}
                        onClick={() => handleClickRemoveVoivodeship(id)}>
                        <span>{voivodeship}</span>
                        <i className="location__icon"></i>
                    </button>
                    <div>
                    {
                        cities.map(({city, id}, index) => selectedCitiesIds.includes(id) ?
                            <button
                                key={city + id}
                                className={`button--small button--outlined location__button--selected button--margin-top ${index > 1 ?'button--margin-left' : ''}`}
                                onClick={() => handleClickRemoveCity(id)}>
                                <span>{city}</span>
                                <i className="location__icon"></i>
                            </button> : null)
                    }
                    </div>
                    <hr/>
                </div>)
            )}
        </div>
    )
}

export default RenderSelectedLocations;
