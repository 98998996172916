import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import ReCaptcha from "./ReCaptcha";
import Button from "./Button";

import {AppDispatch} from "store";
import {getFormData, sendEmail} from "store/formSlice";
import {getVoucherData} from "store/voucherSlice";
import Alert from "components/Alert";
import {voucherStatusEnum} from "../types/voucher";
import {getStatus} from "../store/recaptchaSlice";
import Spinner from "./Spinner";

const EmailResend = () => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const voucher = useSelector(getVoucherData);
    const {voucherStatus} = voucher || {};
    const {error, message} = useSelector(getFormData);
    const isReady = useSelector(getStatus);

    useEffect(() => {
        window.resetRecaptcha();
    }, []);

    const handleResend = async () => {
        try {
            setIsSubmitting(true);
            await window.grecaptcha.execute();
            let recaptcha = window.grecaptcha.getResponse();

            if(!recaptcha){
                window.resetRecaptcha();
                window.executeRecaptcha();
                recaptcha = window.grecaptcha.getResponse();
            }

            const token = voucher.token;
            const hash = voucher.hash;
            return await dispatch(sendEmail({hash, token, recaptcha}));
        } catch (e) {
            console.error(e);
        } finally {
            setIsSubmitting(false);
            window.resetRecaptcha();
            window.executeRecaptcha();
        }
    }

    return (
        <>
            {voucherStatus === voucherStatusEnum.IN_RESERVATION &&
                <div className="app_reservation--title">Voucher w trakcie rezerwacji</div>}
            <p className="app_reservation--subheader">Wysłaliśmy Ci już maila z danymi instruktora bądź firmy, gdzie
                rezerwuje i realizuje się voucher. Aby otrzymać je ponownie kliknij przycisk "Wyślij".</p>

            {/*<div className={'app_voucher-changer--button'}>*/}
            {/*    <ReCaptcha id={'recaptcha-send-email'}/>*/}
            {/*</div>*/}
            <div className={'app_voucher-changer--button'}>
                <Button disabled={isSubmitting || !isReady} className="button--main" onClick={handleResend}>
                    {isSubmitting || !isReady ? (
                        <Spinner position="absolute" size={'small'}/>
                    ) : (
                        'Wyślij'
                    )}
                </Button>
            </div>
            {error &&
                <Alert message={message ?? 'Wystąpił błąd podczas wysyłania wiadomości, spróbuj ponownie później.'}
                       type={'danger'}/>}
        </>
    );
}

export default EmailResend;
