import React, {FC, useEffect, useRef, useState} from 'react';
import ModalWrapper from "components/Modal/ModalWrapper";
import {BoxInterface} from "types/box";
import axios from 'axios';
import Spinner from "components/Spinner";
import {ModalConsumer} from "context/ModalContext";
import {setProductAttribute, setProductId, setProductImage, setProductName} from "store/formSlice";
import {useDispatch} from "react-redux";
import {setAvailableSuppliers} from "store/voucherSlice";

interface RenderViewProps extends BoxInterface {
    html: string;
    closeAllModals: () => void;
}


const RenderView: React.FC<RenderViewProps> = (props) => {
    const dispatch = useDispatch();
    const {html, closeAllModals, id, attribute, productName, image, suppliers} = props;
    const reference = useRef<HTMLDivElement>(null);

    const handleSelectProduct = (e: Event) => {
        e.preventDefault();
        dispatch(setProductId(id));
        dispatch(setProductAttribute(attribute));
        dispatch(setProductName(productName));
        dispatch(setProductImage(image));
        dispatch(setAvailableSuppliers(suppliers));
        window.scrollTo({top: 0, behavior: 'smooth'});
        closeAllModals();
    }

    const productGallery = ()=>{
        if (window.swiper === undefined) {
            window.swiper_object = new window.Swiper('.swiper-container', {
                speed: 400,
                spaceBetween: 100,
            });
        }

      if (reference.current) {
        const prev = reference.current.querySelector('.swiper-button-prev')
        const next = reference.current.querySelector('.swiper-button-next')

        if (prev) {
          prev.addEventListener('click', () => {
            window.swiper_object.slidePrev()
          })
        }

        if (next) {
          next.addEventListener('click', () => {
            window.swiper_object.slideNext()
          })
        }
      }
    }

    useEffect(() => {
        if (reference.current) {
            reference.current.querySelector('#select-product')?.classList.remove('hidden');
            reference.current.querySelector('#app-select-button')?.addEventListener('click', handleSelectProduct);
            productGallery();
        }
    }, [reference]);

    return (
        <div ref={reference} dangerouslySetInnerHTML={{__html: html}}/>
    )
}

const ProductDetails: FC<BoxInterface> = (props) => {
    const {url, attribute} = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [html, setHtml] = useState<string>('');

    const getProductDetails = async () => {
        try {
            setLoading(true);

            const data = new FormData();
            data.append('ajax', '1');
            data.append('action', 'getPopup');
            data.append('id_attribute', attribute.toString());
            const response = await axios.post(url, data);
            setHtml(response.data);

            return response.data;
        } catch (error) {
            console.error('Failed to fetch product details:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getProductDetails().then(r => {});
    }, []);

    if (loading) {
        return <Spinner/>;
    }

    return (
        <ModalWrapper>
            <ModalConsumer>
                {({popModal, closeAllModals}) => (
                    <>
                        <div className={'app_modal-close--button'} onClick={(e) => popModal()}/>
                        <RenderView html={html} closeAllModals={closeAllModals} {...props} />
                    </>
                )}
            </ModalConsumer>
        </ModalWrapper>
    );
}

export default ProductDetails;
