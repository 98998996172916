import React, { FC, ChangeEvent } from 'react';
import 'styles/Checkbox.scss';

interface CheckboxProps {
    className?: string;
    label: string;
    name?: string;
    checked?: boolean;
    error?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    id?: string;
}

const Checkbox: FC<CheckboxProps> = ({ className, label, name, checked, error, onChange, required }) => {
    return (
        <div className={'app_checkbox'}>
            <label className={`label-checkbox ${className}`}>
                <input
                    type="checkbox"
                    name={name ?? ''}
                    className={'not_uniform'}
                    checked={checked ?? false}
                    onChange={onChange ?? (() => {})}
                    required={required ?? false}
                />
                <span className="label-checkbox--text" dangerouslySetInnerHTML={{ __html: label }} />
            </label>
        </div>
    );
};

export default Checkbox;
