import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";

import 'styles/VoucherCheckerForm.scss';

import {Checkbox, TextInput} from "components/Input";
import ReCaptcha from "components/ReCaptcha";
import Alert from "components/Alert";

import useErrorMessage from "hooks/useErrorMessage";
import Button from "components/Button";
import {checkVoucher} from "store/voucherSlice";
import Options from "../Options";
import Spinner from "../Spinner";

const VoucherCheckerForm: React.FC = () => {
    const dispatch = useDispatch<any>();
    const {register, setValue, handleSubmit, formState: {errors}} = useForm();
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [isPrivacyAccepted, setIsPrivacyAccepted] = useState<boolean>(false);
    const [isNewsletterAccepted, setIsNewsletterAccepted] = useState<boolean>(false);
    const error = useSelector((state: any) => state.voucher.error);
    const message = useSelector((state: any) => state.voucher.message);

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked: boolean = e.target.checked;
        setSelectAll(checked);
        setIsTermsAccepted(checked);
        setIsPrivacyAccepted(checked);
        setIsNewsletterAccepted(checked);
    };

    const onSubmit = async (data: any) => {
        try {
            setIsSubmitting(true);
            let recaptcha = window.grecaptcha.getResponse();

            if (!recaptcha) {
                window.resetRecaptcha();
                window.executeRecaptcha();
                recaptcha = window.grecaptcha.getResponse();
            }

            const payload = {...data, isNewsletterAccepted, isPrivacyAccepted, isTermsAccepted, recaptcha};
            await dispatch(checkVoucher(payload));
        } catch (e) {
            console.error(e);
        }finally {
            setIsSubmitting(false);
            window.resetRecaptcha();
            window.executeRecaptcha();
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const $hash = searchParams.get('voucher_number');

        if ($hash) {
            setValue('hash', $hash);
        }

    }, []);

    return (
        <div className="form-container">
            <h1 className="app_page-title">Sprawdź i zarezerwuj prezent</h1>
            <p className="form-subheader">Wpisz numer z vouchera, karty podarunkowej lub kod rabatowy oraz Twój adres e-mail.</p>

            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <TextInput
                    label={'Numer'}
                    placeholder={'Numer vouchera'}
                    error={useErrorMessage(errors, 'hash')}
                    {...register("hash", {required: true, minLength: 4})}
                />
                <TextInput
                    label={'Twój adres e-mail'}
                    placeholder={'Adres e-mail'}
                    onFocus={() => window.showRecaptcha()}
                    error={useErrorMessage(errors, 'email')}
                    {...register("email", {
                        required: "required",
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Podaj poprawny adres email",
                        },
                    })}
                />

                <Checkbox
                    className="label-checkbox--selectAll"
                    onChange={handleSelectAll}
                    checked={selectAll}
                    label={'Zaznacz wszystkie'}
                />

                <Checkbox
                    onChange={(e) => setIsTermsAccepted(e.target.checked)}
                    checked={isTermsAccepted}
                    label={'*Oświadczam, że zapoznałem/am się z treścią <a href="https://prezentmarzen.com/regulamin/" target="_blank" rel="noopener">Regulaminu</a> i akceptuję jego postanowienia w celu dokonania rezerwacji vouchera.'}
                    required
                />
                <Checkbox
                    onChange={(e) => setIsPrivacyAccepted(e.target.checked)}
                    checked={isPrivacyAccepted}
                    label={'*Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu przez EMOTIVO sp. z o.o. w Puławach (24-100), ul. 6 Sierpnia 1, w celu realizacji vouchera zgodnie z <a href="https://prezentmarzen.com/polityka-prywatnosci/" target="_blank" rel="noopener">Polityką prywatności</a>.'}
                    required
                />
                <Checkbox
                    onChange={(e) => setIsNewsletterAccepted(e.target.checked)}
                    checked={isNewsletterAccepted}
                    label={'(Opcjonalnie) Wyrażam zgodę na otrzymywanie na wskazany adres email informacji handlowych, o produktach i usługach oferowanych przez EMOTIVO sp. z o. o. w Puławach.'}
                />

                <div className="form-confirm">
                    <ReCaptcha id={'recaptcha-check-voucher'}/>
                    <Button disabled={isSubmitting} className="button--main button--margin-top">
                        {isSubmitting ? (
                            <Spinner position="absolute" size={'small'}/>
                        ) : (
                            'Sprawdź'
                        )}
                    </Button>
                </div>

                <Options />
                {error && <Alert message={message ?? 'Wystąpił błąd podczas sprawdzania vouchera, spróbuj ponownie później.'} type={'danger'}/>}
            </form>
        </div>
    );
}

export default VoucherCheckerForm;
