import React, {FC, useState} from 'react';
import Logo from 'assets/BezEnd.png';
import Button from "components/Button";
import 'styles/BezEnd.scss';
import {getVoucherData} from "store/voucherSlice";
import {useDispatch, useSelector} from "react-redux";
import {redirectToShoppingCart} from "../store/formSlice";
import {AppDispatch} from "../store";
import {ApiResponse} from "../types/apiResponse";
import Alert from "./Alert";
import Spinner from "./Spinner";
import {getStatus} from "../store/recaptchaSlice";

interface BezEndProps {
    hash?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const BezEnd: FC<BezEndProps> = ({hash}) => {
    const {lifeTimePrice} = useSelector(getVoucherData);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const [message, setMessage] = React.useState<string>('');
    const isReady = useSelector(getStatus);

    if(!hash) return null;

    const onClick = async () => {
        setIsSubmitting(true);
        try {
            const recaptcha: string = window.grecaptcha.getResponse();

            if(!recaptcha) {
                return setMessage('Potwierdź, że nie jesteś robotem... Spróbuj ponownie!');
            }

            const response = await dispatch(redirectToShoppingCart({recaptcha, hash: hash})) as ApiResponse<any>;
            setIsSubmitting(false);
            const {success} = response.data;
            if (success === true) {
                return window.location.href = response.data.data.redirect;
            } else {
                const message = response.data.data ? response.data.data : response.data.error;
                setMessage(message);
            }
        } catch (e) {
            console.error(e);
            setIsSubmitting(false);
        } finally {
            setIsSubmitting(false);
            window.resetRecaptcha();
            await window.executeRecaptcha();
        }
    };

    return (
        <div className={'app_bez-end--relative'}>
            <div className={'app_bez-end'}>
                <div className={'app_bez-end--title'}>Hej, może Cię zainteresować!</div>
                <div className={'app_bez-end--wrapper'}>
                    <div className={'app_bez-end--content'}>
                        <img src={Logo}
                             className={'app_bez-end--content_logo'} alt={'Voucher'}/>
                        <div className={'app_bez-end--content_text'}>
                            <div>
                                <p className={'solid'}>Jeśli dokupisz “Opcję BEZ ENDU” za <span
                                    className={'app_bez-end--bg-text'}>{lifeTimePrice} PLN</span>
                                    to kwota zapłacona za voucher nigdy nie przepadnie!</p>
                                <div className={'app_bez-end--normal-text'}>Po upływie ważności, voucher zmieni się w
                                    bezterminową kartę podarunkową i będzie można wykorzystać ją na zakupy w
                                    Prezentmarzeń.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'app_voucher-changer--button'}>
                        <Button className={'button button--yellow'} onClick={onClick} disabled={isSubmitting || !isReady}>
                            {isSubmitting || !isReady ? (
                                <Spinner position="absolute" size={'small'}/>
                            ) : (
                                'Dokup opcję BEZ ENDU'
                            )}
                        </Button>
                    </div>
                </div>
            </div>
            {message && <Alert message={message} type={'danger'}/>}
        </div>
    );
}

export default BezEnd;
