import {createAction, createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "api/index";
import {ApiResponse} from "types/apiResponse";
import {LocationInterface, LocationState, GroupedVoivodeshipData, CityIdsMapInterface} from "types/location";
import {RootStateInterface} from "types/store";

const initialState: LocationState = {
    selectedCity: [],
    selectedVoivodeship: [],
    data: [],
    cityIdsMap: {},
    loading: false,
};

interface FetchDataSuccessPayload {
    locations: LocationInterface[];
    cityIdsMap: CityIdsMapInterface;
}

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        addCity: (state: LocationState, action: PayloadAction<number>) => {
            const {payload} = action;
            state.selectedCity = [...state.selectedCity, payload];
        },

        removeCity: (state: LocationState, action: PayloadAction<number>) => {
            const {payload} = action;

            const index = state.selectedCity.indexOf(payload);
            if (index !== -1) {
                state.selectedCity.splice(index, 1);
            }
        },

        addVoivodeship: (state: LocationState, action: PayloadAction<number>) => {
            const {payload} = action;
            state.selectedVoivodeship = [...state.selectedVoivodeship, payload];
        },

        removeVoivodeship: (state: LocationState, action: PayloadAction<number>) => {
            const {payload} = action;

            const index = state.selectedVoivodeship.indexOf(payload);
            if (index !== -1) {
                state.selectedVoivodeship.splice(index, 1);
            }
        },
        removeVoivodeshipAndCities: (state: LocationState, action: PayloadAction<number>) => {
            const {payload} = action;
            const found = state.data.find(({id}) => id === payload);

            if (!found) return;

            if (typeof found.cities == undefined) return;

            found.cities.forEach(({id}) => {
                const index = state.selectedCity.indexOf(id);
                if (index !== -1) {
                    state.selectedCity.splice(index, 1);
                }
            });

            const index = state.selectedVoivodeship.indexOf(payload);
            if (index !== -1) {
                state.selectedVoivodeship.splice(index, 1);
            }
        },
        clearStore: (state: LocationState) => {
            state.selectedCity = [];
            state.selectedVoivodeship = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(start, (state: LocationState, action) => {
                state.loading = true;
            })
            .addCase(success, (state: LocationState, action) => {
                state.loading = false;
                state.data = action.payload.locations;
                state.cityIdsMap = action.payload.cityIdsMap;
            })
            .addCase(failed, (state: LocationState, action) => {
                state.loading = false;
            });
    }
});

export const {
    addCity,
    removeCity,
    addVoivodeship,
    removeVoivodeship,
    clearStore,
    removeVoivodeshipAndCities
} = locationSlice.actions;
export const getCityIdsMap = (state: RootStateInterface) => state.location.cityIdsMap;
export const getSelectedCitiesIds = (state: RootStateInterface) => state.location.selectedCity;
export const getSelectedVoivodeshipsIds = (state: RootStateInterface) => state.location.selectedVoivodeship;
export const getData = (state: RootStateInterface) => state.location.data;
export const getSelectedVoivodeships = createSelector([getSelectedVoivodeshipsIds, getData], (selectedIds: number[], data: LocationInterface[]) => {
    if (selectedIds.length === 0) return [];
    if (data.length === 0) return [];

    return data.filter(voivodeship => selectedIds.includes(voivodeship.id));
});
export const getLocations = (state: RootStateInterface) => state.location.data;

const start = createAction('location/fetchDataStart');
const success = createAction<FetchDataSuccessPayload>('location/fetchDataSuccess');
const failed = createAction<string>('location/fetchDataFailure');

export const fetchLocations = createAsyncThunk(
    'location/fetchData',
    async (payload: { hash: string }, {dispatch, getState}) => {
        const {location} = getState() as RootStateInterface;
        if(location.data.length > 0) return;

        try {
            dispatch(start());
            const {hash} = payload;
            const {data} = await axios.post<ApiResponse<LocationInterface[]>>(`/voivodeshipsAndCities`, { hash });

            if (data.code !== 1000) {
                return dispatch(failed(data.message));
            }

            const groupedData: LocationInterface[] = data.data.map(({ voivodeship, id, cities }) => ({
                voivodeship,
                id,
                cities: cities.reduce((acc: GroupedVoivodeshipData[], { city, id }) => {
                    const existingCity = acc.find(item => item.city === city);
                    if (existingCity) {
                        existingCity.ids = existingCity.ids || [];
                        existingCity.ids.push(id);
                    } else {
                        acc.push({ city, id, ids: [id] });
                    }
                    return acc;
                }, [])
            }));

            const cityIds = data.data.reduce((acc: { [key: string]: string[] }, { cities }) => {
                const cityMap = new Map();

                cities.forEach(({ city, id }) => {
                    if (cityMap.has(city)) {
                        cityMap.get(city).push(id);
                    } else {
                        cityMap.set(city, [id]);
                    }
                });

                cityMap.forEach((ids, city) => {
                    const firstId = ids[0];
                    acc[firstId] = ids;
                });

                return acc;
            }, {});

            dispatch(success({ locations: groupedData, cityIdsMap: cityIds }));

            return data;
        } catch (error) {
            console.log(error)
            dispatch(failed("Coś poszło nie tak"));
        }
    }
);

export const getBox = (state: RootStateInterface) => state.box.data;

export default locationSlice.reducer;
