import React, { FC } from "react";

import 'styles/Spinner.scss';

interface SpinnerProps {
    position?: string;
    size?: string;
}

const Spinner: FC<SpinnerProps> = ({ position, size }) => {
    return (
        <div className={`app_spinner app_spinner_${position || 'default'}`}>
            <div className={`app_spinner--container app_spinner--container_${size || 'large'}`}/>
        </div>
    );
}

export default Spinner;
