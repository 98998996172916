import {createSlice} from '@reduxjs/toolkit';
import {RecaptchaStateInterface} from "types/recaptcha";

const initialState: RecaptchaStateInterface = {
    isReady: false
};

const recaptchaSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        isReady(state: RecaptchaStateInterface) {
            state.isReady = true;
        },
        isNotReady(state: RecaptchaStateInterface) {
            state.isReady = false;
        }
    }
});

export const {isReady, isNotReady} = recaptchaSlice.actions;

export const getStatus = (state: { recaptcha: RecaptchaStateInterface }) => state.recaptcha.isReady;
export default recaptchaSlice.reducer;
