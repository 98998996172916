import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addCity, getCityIdsMap,
    getSelectedCitiesIds,
    getSelectedVoivodeships,
    getSelectedVoivodeshipsIds,
    removeCity
} from "store/locationSlice";

import {ModalConsumer} from "context/ModalContext";
import {ModalWrapper} from "components/Modal";
import LocationButton from "components/LocationFilter/LocationButton";
import {fetchBox, resetPage} from "store/boxSlice";
import {AppDispatch} from "store";
import {getHash} from "store/formSlice";

const CitiesPicker = () => {
    const dispatch = useDispatch<AppDispatch>();
    const hash = useSelector(getHash);
    const selectedCitiesIds = useSelector(getSelectedCitiesIds);
    const selectedVoivodeshipIds = useSelector(getSelectedVoivodeshipsIds);
    const selectedVoivodeships = useSelector(getSelectedVoivodeships);
    const citiesMap = useSelector(getCityIdsMap);

    const isSelected = (id: number): boolean => {
        return selectedCitiesIds.includes(id);
    }

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement|HTMLDivElement>, popModal: () => void) => {
        e.preventDefault();
        dispatch(resetPage());
        popModal();
        let city:any[] = [];

        selectedCitiesIds.forEach(id => {
            city = [...city, ...citiesMap[id]];
        });

        dispatch(fetchBox({hash, city, voivodeship: selectedVoivodeshipIds}));
    };

    return (
        <ModalWrapper>
            <ModalConsumer>
                {({popModal}) => (
                    <div className={'app_modal-close--button'} onClick={(e) => handleButtonClick(e, popModal)}/>)}
            </ModalConsumer>
            <div>
                <h1 className={''}>Wybierz miasto</h1>
                <p>(możesz zaznaczyć więcej niż jedno)</p>

                <div className={'cities-wrapper'}>
                    {selectedVoivodeships.map(({voivodeship, cities, id}) => (
                        <div key={voivodeship + id}>
                            <p className="location__region">{voivodeship}</p>
                            <div className={'wrapper-locations wrapper-locations__cities'} key={id}>
                                {cities.map(({city, id}) => (
                                    <LocationButton
                                        key={city + id}
                                        name={city}
                                        id={id}
                                        selected={isSelected(id)}
                                        add={addCity}
                                        remove={removeCity}
                                    />
                                ))}
                                <hr/>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={'app_voucher-changer--button'}>
                    <ModalConsumer>
                        {({popModal}) => (<button type="button" className={'app_box--select-button'}
                                                  onClick={(e) => handleButtonClick(e, popModal)}>Wybieram</button>)}
                    </ModalConsumer>
                </div>
            </div>
        </ModalWrapper>
    )
}
export default CitiesPicker;
