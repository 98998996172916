import React, { FC } from 'react';
import "styles/VoucherGuidebook.scss"
import Content from "components/VoucherGuidebook/Content";
import Gallery from "components/VoucherGuidebook/Gallery/Gallery";

interface InfoItem {
    image: string;
    title: string;
}

const cdn = 'https://cdn.prezentmarzen.com'

const infoItems: InfoItem[] = [
    {
        image: cdn + '/modules/voucherreservation/voucher.jpg',
        title: 'Vouchera',
    },
    {
        image: cdn + '/modules/voucherreservation/giftCard.jpg',
        title: 'Karty podarunkowej',
    },
    {
        image: cdn + '/modules/voucherreservation/reservationCard.jpg',
        title: 'Karty rezerwacyjnej',
    },
];

const VoucherGuidebook: FC = () => {

    return (
        <div className={'panel-container'}>
            <Content title="Jak to działa?" space={false}>
                <ol>
                    <li>Wpisz numer z vouchera lub karty i kliknij SPRAWDŹ w celu uzyskania informacji
                        dotyczących Twojego prezentu tj.: status, data ważności.
                    </li>
                    <li>Kliknij REZERWUJ, aby wyświetlić dane kontaktowe naszego Partnera, realizującego Twój
                        voucher. Otrzymasz je również na podany adres e-mail.
                    </li>
                    <li>Pamiętaj, że pobranie danych kontaktowych nie jest równoznaczne z rezerwacją prezentu.
                        Skontaktuj się z Partnerem oraz umów datę realizacji.</li>
                </ol>
            </Content>
            <Content title="Gdzie znajdę numer:" space={false}>
                <Gallery items={infoItems}/>
            </Content>
            <Content title="Pamiętaj" space={false}>
                Rezerwacja prezentu musi być dokonana z minimum 7 dniowym wyprzedzeniem, nie później niż 30 dni przed
                końcem ważności vouchera lub końcem sezonu (w przypadku prezentów sezonowych). Przed rezerwacją zapoznaj
                się z{' '}
                <a href="/regulamin/" target="_blank" rel="noopener noreferrer">
                    Regulaminem
                </a>
            </Content>
        </div>
    );
}

export default VoucherGuidebook;
